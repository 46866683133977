<template>
	<div class="emb-gadget-wrap mt-14">
		<v-card color="secondary" flat>
			<v-toolbar color="info" dark extended flat> </v-toolbar>

			<v-card color="secondary" class="mx-auto mb-6" max-width="1420" style="margin-top: -64px;" v-if="loaded">
				<v-toolbar flat>
					<v-toolbar-title>Realiza tu reserva</v-toolbar-title>

					<v-spacer></v-spacer>
				</v-toolbar>

				<v-divider></v-divider>

				<v-card-text>
					<v-form v-if="!closed" ref="form" @submit.prevent="submit">
						<v-row>
							<v-col cols="12" xl="4" lg="4" md="12" sm="12">
								<div class="form-group" :class="{ 'error-text': errors.date }">
									<label class="control-label">Fecha</label>
									<el-date-picker
										type="date"
										v-model="form.date"
										value-format="yyyy-MM-dd"
										format="dd/MM/yyyy"
										:picker-options="pickerOptions"
										:clearable="false"
										size="large"
										@change="checkAvailability"
									>
									</el-date-picker>
									<small
										class="form-control-feedback"
										v-if="errors.date"
										v-text="errors.date[0]"
									></small>
								</div>
							</v-col>
							<v-col cols="12" xl="4" lg="4" md="12" sm="12">
								<div class="form-group" :class="{ 'error--text': errors.time }">
									<label>Hora</label>
									<el-time-select
										size="large"
										v-model="form.time"
										value-format="HH:mm"
										format="HH:mm"
										:picker-options="{
											start: `${start}`,
											step: '00:15',
											end: `${end}`,
										}"
										:clearable="false"
									>
									</el-time-select>
									<small
										class="error--text"
										v-if="errors.time"
										v-text="errors.time[0]"
									></small>
								</div>
							</v-col>
							<v-col cols="12" xl="4" lg="4" md="12" sm="12">
								<div
									class="form-group"
									:class="{ 'error--text': errors.number_of_people }"
								>
									<label>N° de personas</label>
									<el-input-number
										size="large"
										v-model="form.number_of_people"
										controls-position="right"
										step-strictly
										:step="1"
										:max="20"
										:min="1"
									>
									</el-input-number>
									<small
										class="error--text"
										v-if="errors.number_of_people"
										v-text="errors.number_of_people[0]"
									></small>
								</div>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12" xl="4" lg="4" md="12" sm="12">
								<v-text-field
									type="text"
									v-model="form.contact.name"
									label="Nombre completo"
									autocomplete="off"
									outlined
									dense
									required
								>
								</v-text-field>
								<small
									class="error--text"
									v-if="errors['contact.name']"
									v-text="errors['contact.name'][0]"
								></small>
							</v-col>
							<v-col cols="12" xl="4" lg="4" md="12" sm="12">
								<v-text-field
									type="email"
									v-model="form.contact.email"
									required
									label="Correo electrónico"
									autocomplete="off"
									outlined
									dense
								>
								</v-text-field>
								<small
									class="error--text"
									v-if="errors['contact.email']"
									v-text="errors['contact.email'][0]"
								></small>
							</v-col>

							<v-col cols="12" xl="4" lg="4" md="12" sm="12">
								<v-text-field
									type="email"
									v-model="form.contact.phone"
									required
									label="Celular"
									autocomplete="off"
									outlined
									dense
								>
								</v-text-field>
								<small
									class="error--text"
									v-if="errors['contact.phone']"
									v-text="errors['contact.phone'][0]"
								></small>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" xl="12" lg="12" md="12" sm="12">
								<v-text-field
									type="text"
									v-model="form.notes"
									label="Notas"
									autocomplete="off"
									placeholder="¿Tienes algún requerimiento en especial? Ej. Cumpleaños, Matrimonios"
									outlined
									dense
									required
								>
								</v-text-field>
								<small
									class="error--text"
									v-if="errors.email"
									v-text="errors.email[0]"
								></small>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" xl="12" lg="12" md="12" sm="12">
								<v-text-field
									type="text"
									v-model="form.observations"
									label="Observaciones"
									autocomplete="off"
									placeholder="Alergias, intolerancias, restricciones alimenticias."
									outlined
									dense
									required
								>
								</v-text-field>
								<small
									class="error--text"
									v-if="errors.email"
									v-text="errors.email[0]"
								></small>
							</v-col>
						</v-row>
						<v-btn
							class="mb-3 ma-0"
							type="submit"
							color="accent"
							:loading="loading"
							block
							:disabled="loading"
						>
							Reservar
						</v-btn>
					</v-form>
					<v-row v-else>
						<v-col cols="4" xl="4" offset-xl="4" class="text-center">
							<v-card color="error" dark>
								<v-card-text class="text-center"
									>No tenemos cupos en éste momento</v-card-text
								>
							</v-card>
							<v-btn color="secondary" class="mt-4" @click="initForm"
								>Intentalo nuevamente</v-btn
							>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-card>
	</div>
</template>

<script>
import { confirm } from 'Mixins/confirm';
import dayjs from 'dayjs';

export default {
	mixins: [confirm],
	data() {
		return {
			closed: false,
			loaded: false,
			loading: false,
			resource: 'user/reservations',
			form: {},
			errors: {},
			pickerOptions: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') > time;
				},
			},
			start: null,
			end: null,
		};
	},
	async created() {
		await this.initForm();
		await this.getOfficeHour();
	},
	methods: {
		initForm() {
			this.form = {
				date: dayjs().format('YYYY-MM-DD'),
				time: dayjs().format('h:mm'),
				number_of_people: null,
				contact: {
					name: null,
					email: null,
					phone: null,
				},
				notes: null,
				observations: null,
			};
			this.closed = false;
		},
		async getOfficeHour() {
			await this.$http.post(`/${this.resource}/office-hour`, this.form).then((response) => {
				this.start = response.data.start_time;
				this.end = response.data.end_time;

				this.loaded = true;
			});
		},
		async checkAvailability() {
			await this.$http
				.post(`/${this.resource}/check-availability`, this.form)
				.then((response) => {
					if (!response.data.success) {
						this.$message.error(response.data.message);
						this.closed = true;
					}
				});
		},
		async submit() {
			this.confirm().then(() => {
				this.loading = true;
				this.$http
					.post(`/${this.resource}`, this.form)
					.then((response) => {
						if (response.data.success) {
							this.$message.success(response.data.message);
							this.initForm();
							// this.$eventHub.$emit('reloadData', this.resource)
							// this.close()
						} else {
							this.$message.error(response.data.message);
						}
					})
					.catch((error) => {
						if (error.response.status === 422) {
							this.errors = error.response.data.errors;
						} else {
							console.log(error);
						}
					})
					.then(() => {
						this.loading = false;
					});
			});
		},
	},
};
</script>
